/* || General Style */

:root {
  box-sizing: border-box;
  font-size: 1rem;	
}

*, ::before, ::after {
  box-sizing: inherit;
}

@media (min-width: 800px) {
:root {
font-size: 1.1rem;
}
}
@media (min-width: 1200px) {
:root {
font-size: 1.3rem;
}
}


body {
  margin: 0;
  font-family: -apple-system, FiraSans, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

// .header {
//   position: fixed;
// }

.team {
  background-color: white;
  border-radius: 1em;
}


/* || Colors */

.firstGrey {
  background-color: rgb(243, 243, 243);
}

.secondColor {
  background-color: rgb(0, 124, 196);
}

.thirdColor {
  background-color: rgb(218, 216, 216);
}

/* || Fonts */

@font-face {
  font-family: "FiraSans";
  src: local("FiraSans"), url(./fonts/FiraSans-Regular.ttf) format("truetype");
}

.card__title {
  font-size: 3rem;
  color: #ffffff;
  text-shadow: 2px 2px black;
  @media only screen and (max-width: 768px) {
    font-size: 2rem;
  }
  @media only screen and (max-width: 575px) {
    font-size: 1.3rem;
  }
}

.card__subtitle {
  font-size: 2rem;
  color: rgb(230, 230, 230);
  text-shadow: 2px 2px rgb(0, 0, 0);
  @media only screen and (max-width: 768px) {
    font-size: 1.5rem;
  }
  @media only screen and (max-width: 575px) {
    font-size: 1.1rem;
  }
}

.card__text {
  color: #ffffff;
  text-shadow: 2px 2px black;
  @media only screen and (max-width: 768px) {
    font-size: 1.1rem;
  }
  @media only screen and (max-width: 575px) {
    font-size: 1rem;
  }
}


.footer__text {
  font-size: 1.5rem;
  color: white;

  @media only screen and (max-width: 575px) {
    font-size: 0.8rem;
  }
}

.project__title {
  font-size: 2rem;
  color: #000000;
  @media only screen and (max-width: 575px) {
    font-size: 1.5rem;
  }
}

.project__text {
  font-size: 1rem;
  color: #000000;
  @media only screen and (max-width: 575px) {
    font-size: .8rem;
  }
}

/* Parallax Scroll */

.paral {
  min-height: 600px;
  background-attachment: fixed;
  background-size: cover;
  background-position: 50% 50%;
}

/* Paragraph for Parallax Section */
.paral p {
  text-align: center;
  line-height: 30px;
}

/* Heading for Parallax Section */
.paral h1 {
  text-align: center;
  padding-top: 60px;
  line-height: 55px;
}

/* Heading for Parallax Section */
.paral h2 {
  text-align: center;
  padding-top: 30px;
  line-height: 45px;
}

/* Image for Parallax Section */
.paralsec1 {
  background-image: url("./assets/images/bckgrndHome01.jpg");
}

.paralsec2 {
  background-image: url("./assets/images/bckgrndHome02.jpg");
}

.paralsec3 {
  background-image: url("./assets/images/bckgrndHome03.jpg");
}

.paralsec4 {
  background-image: url("./assets/images/bckgrndHome04.jpg");
}
